@font-face {
  font-family: 'Reiju-Two';
  src: local('Reiju-Two'),
    url('../fonts/Reiju-Two.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Dosis-Light';
  src: local('Dosis-Light'),
    url('../fonts/Dosis-Light.ttf');
  font-weight: normal;
}

.reiju {
  font-family: 'Reiju-Two';
}
