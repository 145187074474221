.contact {
  transform: translateY(100%);
  transition: 0.9s;
  @apply bg-zinc-900 py-14 mt-36  md:w-full mx-auto shadow-none;
  @media (min-width: 768px) { 
    box-shadow: 45px 2px 0px 15px #383636;
  }
  &-visible{
    transform: translateY(0%) !important;
  }
  &-mail {
    @apply text-3xl text-center mb-4 mt-20 px-2 sm:px-0 font-light;
  }

  &-social {
    @apply flex justify-center gap-x-10 md:gap-x-16 pt-14;

    li {
      @apply hover:text-highlight;
    }
  }
}
