/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-700 hover:bg-gray-600;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(18, 22, 28, .7);
}