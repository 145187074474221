.hover-link {
  overflow: hidden;
  display: inline-flex;
  position: relative;
  padding: 0.2em 0;

  &::after{
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: var(--highlight);
    opacity: 0.6;
  }

  &:hover::after,
  &:focus::after{
    animation: slide 300ms linear;
  }
  
}

@keyframes slide {
  from {
    transform: translate3d(-100%, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}