$hover-transition: .2s ease;

.projects-list {
  @apply container mx-auto lg:p-10;

  &_box {
    @apply rounded-md overflow-hidden bg-cover;
    filter: grayscale(0.3);
    transition: $hover-transition;
    min-height: 11rem;

    @media (max-width: 768px) {
      min-height: 16rem;
    }
    
    &:hover {
      transform: scale(1.05);
      z-index: 1;
      filter: grayscale(0);
    }

    .overlay {
      @apply opacity-80 lg:opacity-0;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      padding: 8px 0;
      transition: $hover-transition;
      box-shadow: inset 0px 0px 9px#b0b0b0;
      background: linear-gradient(145deg, #1b2535, #27334a);
  
      &:hover {
        opacity: 0.95;
      }
  
      &_text {
        font-size: 20px;
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
        font-weight: 700;
        @apply text-white;

        &-description {
          @apply hidden lg:block;
          margin-top: 5px;
          font-weight: 200;
          text-align: justify;
          font-size: smaller; 
        }
      }
    }
  }
}
