@tailwind base;
@tailwind components;
@tailwind utilities;


@import 'fonts';
@import 'scrollbar';

:root {
  --highlight: #18EC59;
}

body {
  margin: 0;
  box-sizing: border-box;
  font-family: 'Dosis-Light';
  font-size: x-large;
  @apply text-gray-50 bg-slate-800; 
}
