.h-wrapper {
  background-color: rgba(18, 22, 28, .7);
  z-index: 2;
  @apply sticky top-0 flex justify-end p-2 gap-x-4 text-2xl;
  
  &_hero {
    @apply cursor-pointer mr-auto ml-4 text-3xl;
  }

  &_link {
    @apply cursor-pointer uppercase hover:text-highlight hover:scale-110 ease-in;
  }
}

.mobile-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  background: black;
  justify-content: center;
  text-align: center;
  align-content: center;
  grid-auto-rows: max-content;
  grid-row-gap: 2em;
  z-index: 2;
  top: 0;
  &_close{
    position: absolute;
    right: 0;
    margin: 26px;
  }
}