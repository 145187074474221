.about-grid {
  @apply grid px-2 md:px-6 lg:px-14 grid-cols-1 lg:grid-cols-12 lg:grid-rows-2 items-center mt-16 opacity-0;
  transform: translateY(30%);
  grid-template-rows: auto;

  &_description {
    background: linear-gradient(145deg, #1b2535, #202c3e);
    box-shadow: 20px 20px 60px #1a2332, -20px -20px 60px #232f44;
    margin-bottom: 9px;
    @apply text-justify rounded-xl p-5 md:p-10 lg:col-start-2 lg:col-span-7 text-xl md:text-2xl;
  }

  &_memoji {
    @apply justify-center m-auto lg:col-span-3;

    &-img {
      animation: float 4s ease-in-out infinite;
    }
  }
  
  &_resume {
    @apply lg:row-start-2 lg:col-start-8 lg:col-span-5 m-auto;
  }

  &_tech {
    @apply text-xl lg:row-start-2 lg:col-start-2 lg:col-span-7 text-gray-300 mb-14 lg:mb-0 mx-auto;

    .tech-container {
      background: linear-gradient(145deg, #1b2535, #202c3f);
      box-shadow:  20px 20px 60px #1a2332,
              -20px -20px 60px #232f44;
      @apply rounded-xl mt-6 w-fit text-gray-100 mx-auto;

      li::marker {
        color: var(--highlight);
      }
    }
  }
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
	}
}